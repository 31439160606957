
.week-display {
    border-radius: 10px;
    display:grid;
    height:98%;
    grid-template-columns: repeat(23, 1fr) .5fr;
    grid-template-rows:1fr 16fr .5fr;
}

.month-display {
    border-radius: 10px;
    display:grid;
    height:98%;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows:1fr 16fr .5fr;
}
  
.week {
    grid-column: 1/25;
    grid-row:2/3;
    display: grid;
    grid-template-columns: repeat(17, 1fr);
    grid-template-rows:repeat(24, 60px);
    overflow-y:scroll;
    background-color: rgb(14, 14, 14);
    border-radius: 15px;
    scrollbar-width: none;
}

.month {
    grid-column: 1/6;
    grid-row:2/3;
    width:100%;
    overflow-y:scroll;
    row-gap: .2em;
    column-gap: .2em;
    scrollbar-width: none;
}

.month-container {
    height:100%;
    width:100%;
    display:grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows:auto;
    column-gap: .2em;
    row-gap: .2em;
}
  
