.window {
    position: absolute;
    height:fit-content;
    background-color: rgba(65, 65, 65, 0.45);
    z-index: 7;
    left:15vw;
    right:15vw;
    top:15vh;
    border-radius: 15px;
    backdrop-filter: blur(20px);
    color:white;
    font-family: Inconsolata;
}

.plan-event-window {
    padding:2em;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 8em;
}

.plan-event-window-title {
    font-family: "Inconsolata";
    padding:2em;
    color:white;
}



input.plan-event-wdw.title {
    background: transparent;
    border: none;
    font-size: x-large;
    padding:0;
    margin:0;
    text-align: center;
    font-family: "Inconsolata";
    margin:2em;
}

input.time {
    border-radius: 4px;
    height:2em;
    width:6em;
    text-align: center;
}


.plan-event-component {
    padding: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.plan-event-component label:not(label.switch) {
    grid-row: 1;
    grid-column: 1;
    text-align: left;
    padding-left:5em;
}

.plan-event-component input {
    grid-row: 1;
    grid-column: 2;
    background: #1a1a1a;
    color:white;
    border:none;
}

input#title {
    display: block;
    grid-row:1;
    color:white;
    grid-column: 1/2;
    text-align: left;
    padding-left:1em;
}

.plan-btn {
    background: #1a1a1a;
    color: white;
    padding:.8em 2em;
    border:none;
    border-radius:25px;
    margin-top:3em;
}

.recurring-type {
    display: inline-block;
    background: #1a1a1a;
    margin:1em;
    cursor: pointer;
}

.recurring-type:hover {
    background: #7ca2f3;
}

.recurring-container {
    grid-column: 1/3;
    grid-row:1;
}