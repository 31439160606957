

.spacrep-container {
  position:absolute;
  top:0;left:0;right:0;bottom:0;
  background-image: url('/public/images/coldsun.jpg');
  display: flex;
  background-size: cover;
}

.spacrep-menu-cid {
   a{
     margin:0;
   }
margin:0;
}

.spacrep-menu-container {
  top: 0;
  height:100%;
  position: absolute;
  left:0;
  //background: linear-gradient(45deg, rgba(181, 239, 241, 0.75), rgba(208, 208, 208, 0.72));
  //border-bottom: .2em solid #d5f8f8;
  text-align: left;
  padding: 2em 1em;
  color: #1c1c1c;
  width:4em;
  align-items: center;
  display:flex;
  flex-direction: column;
  font-family: "JetBrains Mono";

   * {

     &:hover {
       fill: #d5f8f8;
       cursor: pointer;
     }
   }

  a {
    margin-top:0;
  }
}

.spacrep-menu {
  display:flex;
  align-items: center;
  flex-direction: column;
  margin-top:1em;
}

.spacrep-console {
  background: linear-gradient(45deg, rgba(208, 208, 208, 0.72), rgba(241, 241, 241, 0.36));
  //border: .1em solid #d5f8f8;
  backdrop-filter: blur(20px);
  height: 60vh;
  margin:9em auto;
  width: 60vw;
  padding:0 2em;
  box-sizing: border-box;
  color: #1c1c1c;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80% 15% 5%;
  border-radius: 15px;
}

.spacrep-evaluation {
  grid-row:2;
  grid-column: 1;
  display:flex;
  align-items: center;
  justify-content: center;
}

.spacrep-card-type {
  color: #c0fff6;
  text-align: left;
  font-family: monospace;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: left;
}

.spacrep-circle {
  height:1em;
  width:1em;
  display: inline-block;
  background: radial-gradient(#f1a1ff, #c0fff6);
  margin-right:.5em;
  border-radius: 50%;
}

.spacrep-eval-bar {
  grid-row:2;
  grid-column: 1;

  button {
    border-radius: 15px;
    padding: 1em 1.5em;
    font-family: monospace;
    background: linear-gradient(45deg, #fc8d9e, #ffba77, #c0fff6);
    border: none;
    margin:1em;
    color:white;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.16);

    &:hover {
      cursor:pointer;
      color: #1c1c1c;
    }
  }
}

// Mobile version
@media (max-width: 400px) {
  .spacrep-menu-cid {
    font-family: monospace;
  }
  .spacrep-menu-container {
    padding:0;
    font-family: monospace;
    font-size: large;
    margin:0;
  }

  .mobile-menu-toggle {
    padding:0;
    margin:1em .5em;
    cursor:pointer;
  }

  .spacrep-menu {
    z-index:99;
    font-size: x-small;
    position:absolute;
    margin-top:5em;
    height:100%;
    display:flex;
    flex-direction: column;
    svg {
      &:hover {
        cursor: pointer;
        fill:darkorange;
      }
    }
  }

  .spacrep-console {
    margin-top:3em;
    width:96%;
    height:90%;
    padding:1em;
    font-size: medium;
    z-index:50;
  }

  ol, ul {
    padding:0;
    margin:0;
  }


  .card-rep-front {
    width:100%;
    overflow-x:hidden;
    padding:0;
    white-space: normal;
    word-break: break-word;

  }

  .card-rep-back {
    padding:0;
  }

  .card-rep-reference {
    font-size: small;
  }

  .spacrep-img {

  }
}