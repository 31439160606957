.reading-list-ui {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  bottom: 0;
}

.reading-list-display {
  min-height: 10%;
  margin-top: 5%;
}

