/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 23px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1a1a1a;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left:4px;
    bottom: 1px;
    background-color: #282828;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background: linear-gradient(60deg, mediumpurple, skyblue);
}

input:focus + .slider {
    /*box-shadow: 0 0 1px #2196F3;*/
}

input:checked + .slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(33px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}