@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Lora&family=Montserrat:wght@600&family=Playfair+Display:wght@500&display=swap');
:root {
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
}

/*---- START SCREEN ----*/

.start-window {
  background: linear-gradient(45deg, #9882ff, rgb(158, 243, 173));
  color: #101010;
  padding:60px 40px;
  border-radius: 15px;
  font-family: Gloock, serif;
  margin: 0 auto;
  margin-top:40vh;
  width:24vw;
  max-width:300px;
}


.start-window h2 {
  padding: 10px;
  font-weight: 100;
  padding-bottom: 20px;
}




.start-window button {
  background: linear-gradient(60deg, #1f1f1f, #1e1e1e);
  border:none;
  padding:2% 10%;
  border-radius: 20px;
  color: #d3d3d3;
  font-family: monospace;
  font-size: .8em;
}

.start-window button:hover {
  background: linear-gradient(60deg, #a94bff, #56004f);
  color: #c2c2c2;
  cursor:pointer;
}

.start-window:hover {
  color: #440557;
  background: linear-gradient(45deg, rgb(178, 93, 227),#9882ff);

}

.evaluation-bar {
  bottom:0;
  text-align: center;
  left: 0;
  right:0;
  margin:6em 0;
  padding-bottom: 3%;
}


@media (max-width: 400px) {
  .evaluation-bar {
    position:fixed;
    margin:3em 0;
    height:fit-content;
    background:#111111;
    z-index:100;
  }
}




.metadata-bar {
  top:0;
  min-height:3%;
  color:darkgray;
  font-family: monospace;
  text-align: center;
}


.ref-bar{
  color: #544f4f;
  font-size:.9em;
  padding-bottom:1%;
  font-family: monospace;
  margin-top:1%;
  margin-bottom:1%;
}


.tag {
  color: rgb(169, 167, 167);
  text-shadow: 2px 2px 2px rgba(0,0,0,.2);
  padding: .5% .9%;
  background: linear-gradient(60deg, #22325e, rgb(47, 63, 57));
  border-radius: 50px;
  margin: 0 .3%;
}

.tag:hover {
  color: rgb(17, 17, 17);
  background: linear-gradient(60deg, #7bc487, rgb(123, 185, 163));
}

.card {
  color:whitesmoke;
  text-align: center;
}


.card-title {
  padding:4%;
  padding-bottom: 3%;
  font-family: "Arial Nova", sans-serif;
  font-size: 1em;
  color: #ded8d8;

  letter-spacing: 0.03em;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: keep-all;
}

.card-title code {
  font-style: normal;
}


.term {
  font-family: "Gloock", sans-serif;
  font-size: 1.2em;
}

.definition span {
  display: inline-block;
}

.card-title span {
  display: inline-block;
  line-height: 2.3em;
}
.card-items {
  font-family: Arial Nova, sans-serif;
  font-size:.9em;
  color: rgb(175, 169, 169);
  position: relative;
  margin-top:4%;
  line-height:1.7em;
  padding:16% 20%;
  white-space: pre-wrap;
}

.card-items:has(> * li) {
  text-indent: 3%;
  text-align: left;
}

.type-check {
  position: absolute;
  right:0;
}

.card-items span > span > span{
  display: inline-block;
}

.card-items span {
  display: block;
}

.card-items {
  display:inline-block;
}

.card-items .sentence span {
  display: inline;
}

.card-items ul {
  text-align: left;
}


.sl-code {
  padding: .3em .8em;
  margin:0 .3em;
  font-family: "JetBrains Mono", monospace;
  color: #a6adab;
  font-size: .8em;
  background: #171616;
}

.ml-code {
  text-align: left;
  padding: .8em 1.5em;
  width: fit-content;
  padding-right:8em;
}

.masked {
  color:transparent;
  background: linear-gradient(60deg, #363636, rgb(114, 114, 114), rgba(159, 210, 168, 0.84), rgb(97, 157, 155));
  border-radius: 15px;
  min-width:30%;
}


.masked code {
  color: transparent;
}
.masked code {
  background: none;
}


.set-items, .list-items {
  width: fit-content;
  line-height:1.4em;
}

.card-items li, .list-items li{
  margin-bottom: 3%;
}

.list-item {
  margin-bottom: 5%;
}

li.set-item {
  list-style-type: square;
}

.item-count {
  font-family: monospace;
  color: rgb(126, 126, 126);
}

.trans-lan {
  font-family: monospace;
}

.split-container {
  min-width: fit-content;
  max-width: 100%;
  padding: 6%;
  display:grid;
  column-gap: 10%;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

.code-masked {
  color:transparent;
}

.img-masked {
  opacity: 0;
}

.code-text, .image-text {
  padding: 4%;
  grid-row: 1;
  grid-column: 1;
  color: white;
  font-size: .9em;
  text-align: right;
  padding-right:8em;
  font-family: "Arial Nova", sans-serif;
}


.definition {
  text-align: center;
  max-width: 100%;
  margin-top:4%;
  line-height:1.7em;
  letter-spacing: 0.05em;
  text-indent: 0;
}
.image-text {
  margin: auto 0;
  line-height: 3em;
  padding-left: 40%;
}

.italic {
  font-style: italic;
  font-size:1.2em;
}

.img-image {
  grid-row: 1;
  grid-column: 2;
}

.code-code {
  grid-row: 1;
  grid-column: 2;
  padding:4% 4%;
  line-height: 1.5em;
  text-align: left;
  font-family: monospace;
  color: #d7ffde;
  background-color: #2d2a2e;
}

.split-container img {
  border-radius: 15px;
}

.card div {
  margin: 1%;
}

.SpacedRepetition {
  min-height: 100vh;
  top:0px;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: linear-gradient(45deg, #131313, #000000);
  box-sizing: border-box;
}

.CardReviewInterface {
  background-color: rgb(17, 17, 17);
  background-size: cover;
  top:0px;
  position: absolute;
  height:100vh;
  width:100vw;
}

.Card {
  border-radius: 20px;
  position: relative;
  height:80%;
}

/* .blur {
  height:60vh;
  width:90vw;
  left:5vw;
  position:absolute;
  background: linear-gradient(45deg, rgba(255, 255, 255, .7), rgba(190, 190, 190, 0.5));
} */

@media (max-width: 1000px) {


  .card-items {
    padding: 1%;
  }

  .card-title {
    padding: 2% 0;
  }
  .protocard {
    padding:0 2%;
  }

}

@media (max-width: 450px) {
  .review-mode {
    max-width:98%;
    overflow-x:hidden;
    margin:1%;
    margin-right:1%;
  }

  .protocard {
    overflow-wrap: anywhere;
    line-height: 1.5em;
  }

  .evaluation-bar {
    margin: 4em;
    bottom:0;

    margin-top: 4em;
    margin-bottom:5em;
    display:flex;
    justify-content: space-evenly;

  }

  .metadata-bar {
    overflow-wrap: break-word;
  }

  .card-items {
    max-width: 100%;
    display: block;
    min-height: fit-content;
    text-indent: 0;
    text-align: left !important;
  }

  .sentence *:not(.katex *) {
    display: inline-block !important;
    min-width: fit-content;
  }

  li .sentence *:not(.katex *) {
    white-space: nowrap !important;
  }
  
  .sentence {
    max-width: 90vw;
    overflow-wrap: break-word;
  }

  .set .sentence span {
    display: inline-block !important;
  }

  .set .sentence {
    word-break: keep-all;
    overflow-wrap: normal;
    display: inline;
  }
  .ref-bar {
    margin-top:1%;
  }
  


  li {
   max-width: 100%;
   word-break: keep-all;
  }

  li span {
    display: inline-block;
  }

  .card-title {
    padding: 2% 0;
  }

  .evaluate-btn {
    padding: 2% 4%;
    min-width:10%;
    color:ghostwhite !important;
  }
  .protocard {
    padding:2%;
    position: relative;
    margin: 0 auto;
    margin-top:5%;
  }

  .delete-card-block {
    padding:1% 0;
    text-align: center;
    width:100%;
    height:fit-content;
    left:0;
  }



  ul {
    text-indent: 0;
    margin-left:-10%;
  }

  .split-container {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto auto !important;
    position: relative;
    min-height: fit-content;
    row-gap: 10% !important;
  }

  .image-text, .block-left, .code-text {
    grid-row: 1 !important;
    grid-column: 1 !important;
    padding:0;
    height:fit-content;
    text-align: center !important;
  }

  .image-image, .block-right {
    grid-row:2 !important;
    grid-column: 1 !important;
    padding:0;
    text-align: center !important;
  }



  .image-image img {
    object-fit: contain;
  }

  .start-window {
    width:60%;
  }

  /*.config-btn {*/
  /*  width:100%;*/
  /*}*/

  .config-btn {
    color:black;
  }
  .show-answer {
    width:50%;
  }


  .tag {
    padding:.8% 1.6%;
    margin:1%;
  }

  .metadata-bar {
    margin-top:4%;
  }

  .tag {
    display:inline-block;
  }
  .ref-bar {
    margin:1% 1%;
  }

}

  .evaluate-btn-container {
    padding-bottom: 50px;
    bottom: 0px;
    width: 60vw;
    position: relative;
  }


  .evaluate-btn {
    border: none;
    border-radius: 20px;
    text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    min-width: 5%;
    margin: 0 .1em;
    font-family:"Inter", sans-serif;
    color:#000;
    z-index:101;
  }

  .evaluate-btn:hover {
    background: linear-gradient(60deg, #b2afaf, #5b5b5b);
    color: #101010;
  }

  .show-answer {
    padding: .3% 2%;
    background: linear-gradient(60deg, #1c1b1b, #1c1b1c);
    color: #7a7a7a;
    height:4%;

  }

.show-answer:hover {
  background: linear-gradient(60deg, #b2afaf, #5b5b5b);
  color: #101010;
}

.card-meta {
  font-family: monospace;
  padding:2% 0;
}


  .evaluate-btn.S0 {
    background: linear-gradient(85deg, rgb(71, 92, 105), rgb(54, 65, 122));
  }

  .evaluate-btn.S1 {
    background: linear-gradient(85deg,  rgb(54, 65, 122), rgb(72, 30, 108));
  }

  .evaluate-btn.S2 {
    background: linear-gradient(85deg, rgb(72, 30, 108), rgb(122, 56, 119));
  }

  .evaluate-btn.S3 {
    background: linear-gradient(85deg,  rgb(122, 56, 119), rgb(157, 102, 46));
  }

  .evaluate-btn.S4 {
    background: linear-gradient(85deg, rgb(157, 102, 46), rgb(128, 128, 39));
  }

  .evaluate-btn.S5 {
    background: linear-gradient(85deg,rgb(128, 128, 39), rgb(57, 84, 20));
  }

.evaluate-btn.S6 {
  background: linear-gradient(85deg, rgb(57, 84, 20), rgb(26, 65, 25));
}

.evaluate-btn.S7 {
  background: linear-gradient(85deg,rgb(26, 65, 25), rgb(20, 67, 84));
}
.evaluate-btn.S8 {
  background: linear-gradient(85deg,rgb(20, 67, 84), rgb(72, 37, 147));
}

.evaluate-btn.S9 {
  background: linear-gradient(85deg,rgb(72, 37, 147), #b455ce);
}

.evaluate-btn.S10 {
  background: linear-gradient(85deg,#b455ce, #de2ea7);
}

.evaluate-btn.S11 {
  background: linear-gradient(85deg,#de2ea7, #de2e48);
}

  .file-name, .tags {
    text-align: left;
    width: fit-content;
    font-size: 12px;
    padding-top: 60px;
    padding-left: 12px;
    font-family: 'Roboto', sans-serif;
    color: rgba(59, 59, 59, 0.4);
  }

  .tags {
    padding-left: 30px;
  }

  .file-name:hover, .tags:hover {
    color: rgba(34, 34, 34, 0.6);
  }


  code {
    background-color: rgb(33, 33, 33);
    color: white;
    font-weight: 200;
    border-radius: 5px;
    padding: 2% 3%;
  }

  .delete-card-block {
    min-width: 30%;
    left:0;
    top:0;
    text-align: left;
    border-radius: 0;
  }


  .delete-card-block button {
    margin: 2%;
    margin-right:.5%;
  }

  .config-btn {
    background: linear-gradient(60deg, #282828, #2d2a2e);
    border:none;
    color: #000000;
    border-radius:20px;
    padding:.5% 2%;
    font-family: monospace;
    font-style: oblique;
    font-weight:600;
    text-align: right;
    display: inline-block;
  }

  .config-btn:hover {
    color: #101010;
    cursor: pointer;
  }


  .placeholder::before {
    content: "...";
    font-family: monospace;
    color: rgb(201, 190, 255);
    margin: .2em;
  }

.evaluate-btn:hover {
  background: linear-gradient(60deg, #e0e0e0, #a49e9e);
  color: #101010;
  cursor: pointer;
}


.ref-bar .ref:hover, .card-meta:hover {
  color:whitesmoke;
}

.sentence span {
  display: inline;
}

li {
  width: fit-content;
}


.card-title span {
  display: inline-block;
}

.list-items {
  width: 100%;
}

.list-items ol {
}

.list-items ol li {
  width: 100%;
}

.filter-display {
  background: linear-gradient(60deg, #831274, #c9beff);
  padding: 1% 2%;
  max-height:1em;
  margin: 1% 0%;
  min-width:96%;
  width:fit-content;
  font-family: monospace;
  color:white;
}

.config-btn {
  margin: 1% 1%;
}

.input-filter {
  margin-left:3%;
}

