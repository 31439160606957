@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Inter:wght@300&family=Lobster&family=Lora:ital,wght@0,400;1,600&family=Merriweather:ital@1&family=Montserrat:wght@600&family=Nunito:wght@300&family=Playfair+Display:wght@500&family=Roboto+Mono:wght@300&family=Roboto:wght@100&family=Space+Grotesk:wght@500&display=swap");
.card-overview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  bottom: 0;
  overflow-y: scroll;
}

.card-overview-container {
  margin: 9em auto;
  width: 100vw;
  display: grid;
  position: absolute;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-auto-rows: 1px;
  overflow-y: scroll;
  align-items: flex-start;
  justify-content: center;
  grid-column-gap: 0.4em;
}

.card-unit {
  padding: calc(4% + 2em) 2em;
  border-radius: 20px;
  box-sizing: border-box;
  min-width: 250px;
  display: grid;
  grid-template-rows: minmax(1fr, 2fr) 2fr;
  grid-template-columns: 100%;
  grid-row-end: span 25;
  font-size: small;
  color:white;
  position: relative;
  white-space: pre-line;
  word-wrap: break-word;
  background:rgb(19, 19, 19);
}

.overview-card-term {
  font-family: monospace, monospace;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: small;
  color: #6322e8;
  margin: 0 2% 5% 2%;
}

.overview-card-definition {
  font-family: monospace, sans-serif;
  font-size: small;
}

.overview-card-text {
  font-size:small;
  font-family: monospace;
  margin-top:2em;
  text-align: left;
}

.overview-card-definition span, .overview-card-set span {
  display: inline-block;
  font-size: small;
}

.card-unit-tag-pane {
  margin-top: 2em;
  text-align: left;
}

.card-unit-tag {
  font-family: "Roboto Mono", monospace;
  font-size: x-small;
  display: inline-block;
  background: linear-gradient(60deg, #c9beff, #6322e8);
  color: white;
  border-radius: 38px;
  padding: 1% 2%;
  margin: 1%;
}

.card-unit-metadata-pane {
  text-align: right;
  font-family: "Robot mono", monospace;
  margin-bottom: 1em;
  color: #979797;
}

.overview-card-set {
  font-family: monospace;
  text-align: justify;
  font-size: x-small;
  margin:1em;
  margin-top: 3em;
}

.overview-card-set ul {
  padding-left: 1em;
  list-style-position: outside;
  font-size: x-small;
}

.overview-card-set li span {
  vertical-align: top;
}

