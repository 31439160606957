@tailwind base;
@tailwind components;
@tailwind utilities;

/*----------RESET----------*/

* {
  margin:0;
}

a {
  text-decoration: none;
}

/*----------MAIN----------*/


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus {
  outline:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, body, .App, .Home {
  min-height: 100vh;
  min-width: 100vw;
  margin:0;
  background-size: cover;
}

.interface {
  background-color: #101010;;
  position: absolute;
  height:100vh;
  width:100vw;
  text-align: center;
  overflow-y: scroll;

}


@media (max-width: 400px) {
  .interface{
    overflow:hidden
  ;
  }
}


/*----------MENU----------*/

nav {
  margin-top: 0;
  text-align: center;
  display: block;
}

nav ul li a {
  padding:20px;
}

/* nav ul li {
  display: inline-block;
  padding:20px;
} */

a {
  color: white;
  font-weight: 700;
}



/*----------DROPDOWN----------*/

select {
  border: none;
  padding:2px;
}

/*-------------------------------------------------------------*/

html {
  overflow-y:scroll;
  overflow-x:hidden
}

.App, #root, body {
  text-align: center;
  overflow: hidden;
  margin:0px;
  padding:0px;
  height:100vh;
  position:absolute;
}

.Home {
  background-color: pink;
  height: 1000px;
  min-width: 100vw;
}



/*----------NOTEBOOK-INTERFACE----------*/

.NotebookInterface {
  background-color: aliceblue;
  text-align: left;
  padding: 30px;
}

select {
  display: inline-block;
  margin:20px;
}


/*----------NOTES----------*/

.overlay-brown {
  color:rgba(98, 60, 110, 0.7);
}