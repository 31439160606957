@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Inter:wght@300&family=Lobster&family=Lora:ital,wght@0,400;1,600&family=Merriweather:ital@1&family=Montserrat:wght@600&family=Playfair+Display:wght@500&family=Roboto+Mono:wght@300&family=Roboto:wght@100&family=Space+Grotesk:wght@500&display=swap');


.object-db-ui {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: #1a1a1a;
}

.object-db-ui-menu {
  top:4em;
  position: absolute;
}

.odb {
  color: #666767;

  &:hover {
    color:#7bc487;
  }
}

.object-db-display {
  position: absolute;
  top: 7em;
}