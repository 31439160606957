.task-display {
    /* background-color: rgb(14, 14, 14); */
    border-radius: 10px;
    display:grid;
    height:98%;
    grid-template-columns: .25fr repeat(7, 1fr) .25fr;
    grid-template-rows:1fr 16fr .5fr;
}

.tasks-week {
    grid-row: 2/3;
    grid-column: 1/10;
    overflow-y: scroll;
    display: grid;
    column-gap: .6em;
    row-gap: .2em;
    scrollbar-width: none;
    grid-template-rows:1;
    grid-template-columns: repeat(7, 1fr);
}

.tasks-month {
    grid-row: 2/3;
    grid-column: 1/10;
    overflow-y: scroll;
    display: grid;
    column-gap: .2em;
    row-gap: .2em;
    scrollbar-width: none;
    grid-template-rows:auto;
    grid-template-columns: repeat(5, 1fr);
}

.tasks::-webkit-scrollbar {
    display:none;
}

.btn-add:hover {
    fill:purple;
    cursor:pointer;
}