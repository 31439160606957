.task {
    margin: .7em 1em;
    display:flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Mono";
    font-size:small;
    width:fit-content;
}

.task-dot {
    border-radius: 50%;
    height:1em;
    width:1em;
    background-color: bisque;
    display: inline-block;
}

.task-dot.household {
    background: linear-gradient(45deg, pink, purple);
}

.task-dot.productivity {
    background: linear-gradient(45deg, rgb(192, 251, 255), rgb(0, 128, 75));
}

.task-dot.exercise {
    background: linear-gradient(45deg, rgb(192, 251, 255), rgb(0, 128, 75));
}

.task-dot.work {
    background: linear-gradient(45deg, rgb(247, 231, 18), rgb(255, 154, 39));
}

.task-dot.finance {
    background: linear-gradient(45deg, rgb(226, 226, 226), rgb(168, 141, 241));
}

.task-title {
    margin: .1em 1em;
    color:white;
}