.add-task-window {
    position: absolute;
    height:fit-content;
    width:25vw;
    background: #c7c7c7;
    border-radius: 30px;
    border: .1em solid grey;
    padding:3em 3em;
}

.add-task-bg {
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    right:0;
    z-index:99;
    bottom:2em;
    background: rgba(9, 9, 9, 0.11);
    backdrop-filter: blur(20px);
    border-radius: 20px;
}

.task-input {
    background: transparent;
    border:none;
    /*text-align: center;*/
    text-align: left;
}

.task-component {
    display:grid;
    padding:2em 1em;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    color:grey;
}

label {
    grid-column: 1;
    text-align: left;
}

.task-input {
    grid-column: 2;
}


.task-title .task-input {
    grid-row: 1;
    grid-column: 1/3;
    color: #5e5e5e;
    font-size: large;
    text-align: center;
}


.btn {
    display: inline-block;
}

.btn button {
    width: 14em;
}

.task-component.subtasks {
    display:grid;
    grid-template-columns: 1fr 3fr;
}

.subtask {
    display:flex;
    align-items: center;
    justify-content: center;
    position:relative;
    width:100%;
}

.subtask textarea {
    width:100%;
    resize: none;
    align-items: center;
    display: flex;
}

.task-dot-add {
    height:1em;
    width:1em;
    background: linear-gradient(45deg, yellow, greenyellow);
    display: flex;
    border-radius: 50%;
    margin-right: 1em;
    align-items: center;
    justify-content: center;
}




.add-subtask {
    position: absolute;
    left:2em;
}

.add-subtask button {
    background: transparent;
    color:grey;
    display:flex;
    align-items: center;
    justify-content: center;
}