
.tracking-unit-menu-add-unit {
    margin: 2em 3em;
    font-family: "JetBrains Mono", monospace;
}

.tracking-unit-menu-add-unit h2 {
    margin-bottom: 2em;
}

.tracking-unit-menu-add-unit input {
    margin: 2em 1em 2em 1em;
    background: transparent;
    border: none;
    border-bottom: 0.1em solid black;
}

.tracking-unit-menu {
    width:30em;
    position:absolute;
}