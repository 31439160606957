@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Lobster&family=Lora:ital,wght@0,400;1,600&family=Merriweather:ital@1&family=Montserrat:wght@600&family=Playfair+Display:wght@500&family=Space+Grotesk:wght@500&display=swap');

 .quote-ui {
  background:#1a1a1a;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}


.quote-display {
  color:white;
  margin: 12% auto 3% auto;
  font-family: "Times New Roman", serif;
  font-size: x-large;
}

.quote-text {
  max-width: 40%;
  word-wrap: anywhere;
  text-align: center;
  margin: 0 auto;
}

.author-quote {
  font-family: "Arial Nova", sans-serif;
}

.quote-quote {
  font-family: "Space Grotesk", serif;
  margin: 0 .5%;
  font-size:xx-large;
}

.quote-action-container {
  overflow-y:scroll;
  position: relative;
  min-height:50%;
  height:fit-content;
}

@media (max-width: 400px) {

  .quote-display {
    margin-top: 20%;
    font-size: medium;
  }

  .author-quote {
    font-size: small;
  }

}