@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Inter:wght@300&family=Lobster&family=Lora:ital,wght@0,400;1,600&family=Merriweather:ital@1&family=Montserrat:wght@600&family=Nunito:wght@300&family=Playfair+Display:wght@500&family=Poppins&family=Roboto+Mono:wght@300&family=Roboto:wght@100&family=Rubik:wght@300&family=Space+Grotesk:wght@500&display=swap");
.menu-sr {
  cursor: pointer;
  padding-left: 1em;
  right: 0;
  top: 0;
  border-radius: 15px;
  text-align: left;
  z-index:11;
}
.menu-sr svg {
  left: 0;
}
.menu-sr svg:hover {
  fill: #68c5a3;
}

.sliders {
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.config-menu-sr {
  backdrop-filter: blur(20px);
  left: 0;
  right: 0;
  padding-top: 7em;
  height: calc(100vh - 7em);
  position: absolute;
  z-index: 98;
  text-align: center;
  margin-top: 1em;
}

.highlight {
  fill: #8a6cff;
}

.config-btn-sr {
  padding: 1%;
  font-family: "JetBrains Mono", monospace;
  color: white;
  display: block;
  background: none;
  margin: auto;
  text-align: center;
  border: none;
  cursor: pointer;
  font-size: medium;
}
.config-btn-sr:hover {
  color: #e0dc23;
}

.data-label {
  color: #696868;
}

.review-mode {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tag {
  background: linear-gradient(45deg, rgba(104, 197, 163, 0.85), #6322e8);
  color: #1a1a1a;
  padding: 0.4em 0.7em;
  font-family: "Inconsolata", monospace;
}

@media (max-width: 400px) {
  .tag {
    font-size: small;
    background: linear-gradient(45deg, rgba(63, 128, 104, 0.85), #9ef3ad);
  }
}

.ref-bar {
  margin: 0 1em;
  margin-top: 7%;
  margin-bottom:2%;
  color: #8b8a8c;
  font-size: small;
  font-family: "Inter", sans-serif;
}

.card-meta {
  font-family: "Inter", sans-serif;
  font-size: small;
  line-height: 3em;
  padding:.6em;
  position: absolute;
  top:0;
  right:14em;
  color: #b8d9d2;
  z-index:0;
}

.metadata-bar {
  margin-bottom: 4%;
}

@media (max-width: 400px) {
  .ref-bar {
    margin-top: 7%;
  }
}
.edit-card {
  margin-left: 0.35em;
  margin-top: 0.7em;
}


.field {
  color:#2c2c2c;
}


@media (max-width: 450px) {
  .card-meta {
    bottom: 0;
    top: unset;
    background: #111111;
    width: 100%;
    left:0;right:0;
    position:fixed;
    z-index:98;
    margin-left:0;
    padding-left:0;
  }

  .review-mode {
    overflow: hidden;
  }
}