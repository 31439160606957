@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@500&family=Inter:wght@300&family=Lobster&family=Lora:ital,wght@0,400;1,600&family=Merriweather:ital@1&family=Montserrat:wght@600&family=Nunito:wght@300&family=Playfair+Display:wght@500&family=Poppins&family=Roboto+Mono:wght@300&family=Roboto:wght@100&family=Space+Grotesk:wght@500&display=swap");
.menu-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-top: 15%;
  padding-right: 2em;
  box-sizing: border-box;
  padding-left:2em;
  z-index: 100;
  background:transparent;

}

.blur {
  backdrop-filter: blur(20px);
}

.menu-container:hover {
  animation: menu-slide 1s 0s 1 linear forwards;
}

.menu-button {
  display: block;
  font-family: "monospace", sans-serif;
  padding: 0;
  color: #cccccc;
  text-align: right;
}
.menu-button:hover {
  animation: button-slide 1s 0s 1 linear forwards;
}
.menu-button:hover * {
  animation: menu-highlight linear 0s 0s 1 normal forwards;
}

.menu-closed-desktop {
  position: absolute;
  top:.6em;
  right:.6em;
  cursor: pointer;
  fill: #fcfcfc;
  /*background: linear-gradient(45deg, #424242, #262626);*/
  border-radius: 10px;
}

.menu-closed-desktop:hover {
  fill: #9ef3c9;
  background: transparent;
}

@keyframes menu-slide {
  from {
    margin-left: 0;
  }
  to {
    margin-left: -60%;
    background: rgba(17, 17, 17, 0.49);
  }
}

@keyframes button-slide {
  from {
    margin-left: 0;
  }
  to {
    margin-left: -60%;
  }
}
.button-menu-unfold {
  display: none;
}

@media (max-width: 400px) {
  .button-menu-unfold {
    display: block;
    position: absolute;
    top: 1.2em;
    right: .9em;
    background: none;
    outline: none;
    color: #a6fffd;
    border: none;
    font-family: "Inconsolata", sans-serif;
    font-weight: 500;
    transform: rotate(-90deg);
    cursor: pointer;
    font-size: medium;
  }

  .button-menu-unfold:hover {
    color: #9ef3ad;
  }

  .menu-container {
    padding-right:1em;
    padding-left:1em;
  }

  .menu-button {
    color: #efbfef;
  }



  .menu-container {
    padding-top: 50%;
  }
  .menu-container.blur {
    backdrop-filter: blur(30px);
  }
}
.active-menu-btn {
  /*color: #c9beff;*/
  background-image: linear-gradient(45deg, purple, #9680d0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes menu-highlight {
  to {
    background-image: linear-gradient(60deg, white, whitesmoke);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

