.tracking-label {
    text-align: left;
    text-indent: 1em;
    margin: 3% 0 3% 0;
    font-family: "Inconsolata", monospace;
    color:whitesmoke;
}

.tracking-progress-bar {
    border-radius: 30px;
    background: rgba(49, 49, 49, 0.91);
    box-shadow: inset 2px 6px 10px rgba(26, 26, 26, 0.55);
    height: 1.5em;
    z-index: 3;
    width: 90%;
    display: inline-block;
    cursor: pointer;
    margin-bottom:1em;
}