.Day {
  background: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(20px);
  height:90px;
  width: 90px;
  border-radius: 5px;
  margin: 1%;
  color:white;
  font-family: "Arial", sans-serif;
}

.Day:hover {
  animation: button-bounce 1 300ms ease-out;
  cursor: pointer;
}


@keyframes button-bounce {
  from {
    transform: scale(100%);
  }
  50% {
    transform: scale(110%);
  }
  to {
    transform: scale(100%);
  }
}


@media (max-width: 400px) {
  .Day {
    height:40px;
    width:40px;
    font-size: medium;
  }
}