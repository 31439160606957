@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Lora&family=Montserrat:wght@600&family=Playfair+Display:wght@500&display=swap');

.ui-dark {
  background: #080808;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.date-header {
  display:inline-block;
}

.mode-switcher {
  color:grey;
  display:flex;
  align-items:end;
  justify-content: end;
  height:10%;
  right:2%;
  left:2%;
  position: absolute;
}

.planner-ui {
  position:absolute;
  top:10%;
  left:2%;
  right:2%;
  bottom:0%;
  
}

.mode-switcher button {
  margin:0 1% 1% 0;
  display:block;
  font-family: "Bitter";
  cursor: pointer;
  background: rgb(14, 14, 14);
  border-radius: 15px;
  color:white;
  border:none;
  display:flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0.3em 1em;
}

.mode-switcher button:hover {
  background-color:rgb(162, 160, 255);
  color:black;
}

.timeline {
  grid-column: 1/2;
  grid-row:1/25;
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(14 1fr);
}

.timeline .hour-block:first-of-type {
  border-top-left-radius: 10px;
}

.timeline .hour-block:last-of-type {
  border-bottom-left-radius: 10px;
}


.timeline:last-child {
  border-bottom: none;
  border-bottom-left-radius: 10px;
}

.timeline:nth-last-child(1) {
  border-bottom-left-radius: 10px;
}

.week-container {
  grid-column:2/18;
  grid-row:1/25;
  display:grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(24, 1fr);
}


.add-task-btn {
  right:2em;
  position: absolute;
}



.Mon {
  grid-column:1;
}
.Tue {
  grid-column: 2;
}
.Wed {
  grid-column: 3;
}
.Thu {
  grid-column: 4;
}
.Fri {
  grid-column: 5;
}
.Sat {
  grid-column: 6;
}
.Sun {
  grid-column: 7;
}