.display-header-container {
    grid-column: 1/25;
    grid-row:1;
    display: flex;
    align-items: end;
    justify-content: center;
}


  .display-header {
    padding: 2em 2em;
    color:rgb(216, 206, 216);
    font-size: x-small;
    font-family: "Roboto Mono";
    width:100%;
    margin: 1em auto;
    background: rgb(14, 14, 14);
    /* background: linear-gradient(45deg, rgb(255, 236, 239), rgb(212, 180, 255)); */
    height:1.5em;
    border-radius: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
  }


  .display-header h2 {
    display: inline;
  }


  .arrow-date:hover {
      fill:purple;
  }