.task-week {
    background-color: rgb(14, 14, 14);
    width:100%;
    border-radius: 15px;
    height:100%;
}

.task-day {
    background-color: #080808;
    border-radius: 15px;
    height:100%;
    width:100%;
}

.task-month {
    background-color: rgb(14, 14, 14);
    border-radius:8px;
    height:12em;
}



