.flatpickr-input {
    visibility: hidden;
    height:.1em;
}

.flatpickr-calendar {
    background-color: rgba(255, 255, 255, 0.637);
    backdrop-filter: blur(20px);
    border:none;
    color:black;
    position:absolute;
}

.flatpickr-day {
    color:black;
}

.flatpickr-day:hover {
    background: linear-gradient(45deg, rgb(46, 148, 92), rgb(69, 233, 225));
    border: none;
    color:white;
}

.flatpickr-day.selected {
     background: linear-gradient(45deg, blue, pink);
     border:none;
}

.flatpickr-day.selected:hover {
    background: linear-gradient(45deg, blue, rgb(192, 255, 224));
    border:none;
}

.flatpickr-input {
    background-color: rgba(255, 255, 255, 0.637);
    position: absolute;
    right:1em;
    top:5em;
}


