.sr-config-ui {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: #1a1a1a;
}

.sr-header{
  margin-top:20%;
  font-family: "JetBrains Mono Extra Bold", monospace;
  color: #c9beff;

  h1 {
    margin-bottom: 3%;
  }

  p {
    color:white;
    font-family: "Arial Nova", sans-serif;
    padding: 1%;
    text-align: left;
    max-width:50%;
    margin: 0 auto;
  }

  h3 {
    text-align: left;
    color: #ff8eff;
    max-width: 50%;
    margin: 0 auto;
  }
}