.ui {
  background-image: url("../../public/images/bubble.svg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position-x: 25%;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.form-container {
  width:100%;
  min-height:50%;
  height:fit-content;
  margin:2% auto;
  border-radius: 30px;
  backdrop-filter: blur(20px);
  color: #777777;
  position:absolute;
  z-index:99;
}

.form-container-header {
  font-family: "JetBrains Mono", monospace;
  font-size:medium;
  line-break: anywhere;
  margin-bottom: 5em;
}

.form {
  display:grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows:auto;
  grid-row-gap: 16%;
  width:60%;
  height:100%;
  position:relative;
  margin:0 auto 5% auto;
}

.form textarea {
  font-family: "Times New Roman", serif;
  color:white;
  background: transparent;
  min-height:100%;
  height:5em;
  resize:none;
  border-radius: 35px;
  outline: none;
  border: none;
  margin: 0 18% 4% 0;
  padding: 1% 2%;
}


.form input {
  background: #1e1e1e;
  border-radius: 65px;
  border:none;
  padding:.5em 2%;
  color:white;
  max-width: 80%;
  margin: 0 18% 4% 0;
  text-align: left;
}

.form span {
  font-family: "JetBrains Mono", monospace;
  margin: 0 28%;
  text-align: left;
}


.form input.gf-0, .form textarea.gf-0, .form select.gf-0  {
  grid-row: 1;
  grid-column: 2;
}

.form span.gf-0  {
  grid-row: 1;
  grid-column: 1;
}

.form input.gf-1, .form textarea.gf-1, .form select.gf-1   {
  grid-row: 2;
  grid-column: 2;
}

.form span.gf-1  {
  grid-row: 2;
  grid-column: 1;
}

.form span.gf-2  {
  grid-row: 3;
  grid-column: 1;
}

.form input.gf-4, .form textarea.gf-4, .form select.gf-4    {
  grid-row: 5;
  grid-column: 2;
}

.form span.rf-4 {
  grid-row: 5;
  grid-column: 1;
}

.form input.gf-3, .form textarea.gf-3, .form select.gf-3 {
  grid-row: 4;
  grid-column: 2;
}

.form span.gf-3 {
  grid-row: 4;
  grid-column: 1;
}

.form input.gf-5, .form textarea.gf-5, .form select.gf-5 {
  grid-row: 6;
  grid-column: 2;
}

.form span.gf-5 {
  grid-row: 6;
  grid-column: 1;
}

.form-container-footer {
    padding:5% 5%;
    height:fit-content;
    bottom:0;
}

.form-button {
  border-radius:45px;
  background: #1a1a1a;
  color:white;
  font-family: "JetBrains Mono", monospace;
  padding:.6% 3%;
  border:none;
  bottom:10%;
  margin:4% auto;
  cursor:pointer;
}

.form-button:hover {
  background: hotpink;

}

.form select {
  background: #1a1a1a;
  padding:.1% 6%;
  margin:unset;
  color:white;
  border-radius: 15px;
  grid-row: 3;
  max-width: 82%;
  max-height: 2em;
  grid-column: 2;
  font-family: "JetBrains Mono", monospace;
}

@media (max-width: 400px) {
  .form-container {
    width:95%;
    margin-top:2%;
    max-height:75%;
    overflow-y:scroll;
  }


  .form {
    grid-template-rows: repeat(8, auto) ;
    grid-template-columns: 1fr;
    margin:0 auto;
    width:100%;
    padding:unset;
    height:80vh;
    text-align: left;
  overflow-y:scroll;
    grid-row-gap: 3em;
  }

  .form-container-header, .form-container-header {
    font-size: small;
    min-height:fit-content;
    overflow-x: clip;
    line-break: anywhere;
    height:fit-content;
  }

  .form-container-header h1 {
    margin-top: 5%;
  }

  .form span {
    height:fit-content;
  }

  .form input{
    margin: 0 auto;
    width:80%;
    max-height: 1em;
  }
  .form span {
    margin: 6% auto -10% auto;
    width:90%;
  }

  .form input.gf-0, .form textarea.gf-0, .form select.gf-0 {
    grid-row: 2;
    grid-column: 1;
  }

  .form span.gf-0  {
    grid-row: 1;
    grid-column: 1;
  }

  .form input.gf-1, .form textarea.gf-1, .form select.gf-1  {
    grid-row: 4;
    grid-column: 1;
  }

  .form span.gf-1  {
    grid-row: 3;
    grid-column: 1;
  }

  .form span.gf-2 {
    grid-row: 5;
    grid-column: 1;
  }

  .form input.gf-2, .form textarea.gf-2,.form  select.gf-2  {
    grid-row: 6;
    grid-column: 1;
  }

  .form span.gf-3 {
    grid-row: 7;
    grid-column: 1;
  }

  .form input.gf-3, .form textarea.gf-3, .form select.gf-3 {
    grid-row: 8;
    grid-column: 1;
  }

  .form span.gf-4 {
    grid-row: 9;
    grid-column: 1;
  }

  .form select.gf-4, .form textarea.gf-4, .form input.gf-4 {
    grid-row: 10;
  grid-column: 1;
  }

  .form-container-footer {
    bottom: 0;
    vertical-align: bottom;
    width:90%;
  }

  .form-container-footer button {
    margin:5% auto;
  }

  .form textarea {
    margin:0 auto;
    padding:6%;
    width: 80%;
  }


}