.config-link {
  position:fixed;
  width: 8%;
  top:10%;
  border-radius: 15px;
  text-align: left;
  padding-left:.5%;
  cursor:pointer;
  right:0;

  svg {
  }

}

.no-cards-left {
  position: absolute;
  top:45%;
  width:100vw;
  font-family: "JetBrains Mono Extra Bold", monospace;
  color: #c9beff;
}

.config-link:hover {
  svg {
    fill:white;
  }
}

@media (max-width: 400px) {
  .config-link {
    text-align: right;
    height:fit-content;
    top:.8em;
    left:.8em;
    z-index:99;
  }
}

