.split-container {
    display:grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
}


.block-left {
    grid-row: 1;
    grid-column: 1;
    text-align: right;
    padding-right:10%;
}

.block-right {
    grid-row:1;
    grid-column: 2;
    text-align: left;
    padding-left:5%;
}

.block ul li {
    list-style-type: none;
}

.block li {
    display: inline;
}

.block ul {
    display: inline;
    padding:0;
    margin:0;
    text-indent: 0;
}

.trans-lan {
    color: #4c96cc;
    margin: 0 2%;
}