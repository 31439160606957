.add-quote-ui {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: #7bc487;
  padding:2em;
}

.add-quote-ui textarea {
  width:90%;
  min-height:40%;
  margin-top:20%;
  outline:none;
  border:none;
  background: transparent;
  resize: none;
}

.add-quote-ui textarea::before {
  content:open-quote;
  font-size: 20em;
  color:black;

}

.add-quote-ui input {
  background: none;
  border:none;
  outline:none;
  font-style: italic;
  font-size:1.1em;
  font-family: "Playfair Display", serif;
}

.add-quote-ui button {
  margin: 50% auto;
  bottom:0;
  border-radius: 15px;
  border:none;
  padding:2% 5%;
  display: block;
}

.add-quote-ui .tag-input {
  margin-top:20%;
  font-family: monospace;
  text-align: center;
  display:block;
  position:absolute;
}