.task-container {
  display: flex;
}

.task-blob {
  height: 30px;
  width:30px;
  border-radius: 50%;
  display:inline-block;
  margin:1% 2% 1% 0;
}

.research {
  background: linear-gradient(45deg, purple, pink);
}

.chore {
  background: linear-gradient(65deg, #69ecb6, #0a4569);
}

//.task-container:hover {
//  .task-blob {
//    animation: rotate-blob infinite 3s linear;
//  }
//
//}
//
//@keyframes rotate-blob {
//  from {
//    transform: rotate(0deg);
//  }
//
//  to {
//    transform: rotate(360deg);
//  }
//
//}

.task-container span {
  transform: translateY(25%);
}

.task-blob span {
  padding:2% 0 0 0;
}