.ui-login {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #1a1a1a;
}

.ui-main {
  position: absolute;
  width: 40vw;
  height: 50vh;
  margin: 24vh 30vw;
  text-align: center;
  z-index: 5;
  display: grid;
  justify-content: center;
  align-items: center;
}

.ui-main input {
  display: block;
  margin: 2em;
  border-radius: 10px;
  padding: 0.15em 2em;
  width: 14em;
  border: none;
  background: rgba(255, 255, 255, 0.91);
  backdrop-filter: blur(20px);
  font-family: "JetBrains Mono", monospace;
}

.ui-main button {
  display: block;
  background: linear-gradient(60deg, #80005c, #f15eb8);
  border: none;
  cursor: pointer;
  margin: -30em auto 0 auto;
  font-family: monospace;
  padding: 0.2em 0.6em;
  border-radius: 10px;
  color: #070707;
}
.ui-main button:hover {
  background: linear-gradient(60deg, #6322e8, #c9beff);
}

.login {
  margin-top: 5em;
}

#bubble {
  background: url("../../public/images/bubble2.png");
  height: 200px;
  width: 200px;
  background-size: cover;
  position: absolute;
  z-index: 2;
  animation: rotate-bubble linear 3s 0s infinite running both;
}

@keyframes rotate-bubble {
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 400px) {
  .ui-main button {
    margin: -5em auto 0 auto;
  }
}

/*# sourceMappingURL=login_page.css.map */
