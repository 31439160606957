.field-filter-menu {
    color:white;
    position:fixed;
    width:40%;
    background-color: #161616;
    margin: 30%;
    border-radius:10px;
    padding:4em 2em 2em 2em ;
    font-family: monospace;
    height:30%;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 95% 5%;
    z-index:99;
}




.field-filter-blur {
    /*backdrop-filter: blur(20px);*/
    position: absolute;
    top:0;
    right: 0;
    left:0;
    bottom:0;
}

.active-field {
    margin: .2em .4em;
    background-color: aquamarine;
    color:#161616;
    padding: .2em .6em;
    border-radius: 15px;
    display: inline-block;
}

.active-field:hover {
    background: #7ca2f3;
    color:white;
}


.remove-field {
    cursor: pointer;
}

.active-field-container {
    grid-row:1;
    grid-column: 1;
    bottom:8em;
    height:100%;
    width:100%;
    left:10%;
    overflow-y:scroll;
    -ms-overflow-style: none;
    overflow-x:hidden;
}


.active-field-container::-webkit-scrollbar {
    display:none;
}

.field-selector {
    width:100%;
    height:100%;
}

.field-selector select {
    width:100%;
    margin: 0;
}



.cls-field-picker {
    top:1em;
    left:1em;
    z-index:999999;
    cursor: pointer;
    position: absolute;
    border-radius: 5px;
    border:none;
    font-family: monospace;
}

.cls-field-picker button {
    font-family:monospace;
    border: none;
    box-sizing: border-box;
    border-radius:5px;
    cursor:pointer;
    padding:.25em .5em;
    background: #383838;
    color: #ddd1ff;
}
.cls-field-picker button:hover {
    background: #737373;
    color:black;
}

@media (max-width: 450px) {
    .field-filter-menu {
        width:90%;
        position: fixed;
        margin:0 auto;
        top:15%;
        padding:3em 1em 1em 1em ;
        height:60%;
        grid-template-rows: 85% 15%;
    }

    .field-selector select {
        margin:0;
        margin-top:1em;
        width:100%;
    }


}

