.graph-container {
  top:1em;
  left:1em;
  right:1em;
  bottom:1em;
  position: absolute;
  display:grid;
  grid-template-columns: repeat(2, calc((100vw - 2em) / 2));
  grid-column-gap: 1em;
  grid-template-rows: repeat(12, calc((100vh - 2em) / 12));
  overflow: hidden;
  background: linear-gradient(60deg, #171717, #000000);
}

.search-graph-container {
  position: absolute;
  background: linear-gradient(45deg, rgba(87, 87, 87, 0.38), rgba(21, 21, 21, 0.62));
  backdrop-filter: blur(10px);
  border-radius: 45px;
  display:grid;
  align-items: start;
  box-sizing: border-box;
  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: 1fr 1fr;
  padding:2em;
  justify-content: start;
  grid-row: 12/13;
  grid-column:1/2;
  width:100%;
  height:calc(100% - 2em);
  margin:1em;

  input {
    grid-row: 1/2;
    grid-column: 1/3;
    height:2em;
    border:none;
    outline: none;
    border-radius: 15px;
    padding: .2em 2em;
    background: linear-gradient(45deg, #131313, #232222);
    color: #a4a4a4;
    font-family: monospace;
  }
}

.force-graph-container {
  position: absolute;
}

.legend-container {
  grid-row: 2/4;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  flex-flow: row;
}

.legend-block {
  display:flex;
  align-items: center;
  margin:1em;
}

.legend-dot {
  grid-row: 1;
  grid-column: 1;
  height:1em;
  width:1em;
  border-radius: 50%;
}

.legend-label {
  grid-row:1;
  grid-column: 1;
  margin-left:1em;
  font-family: monospace;
}