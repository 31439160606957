

.taskboard {
    background: linear-gradient(45deg, #afa9ef, #9ef3c3, #60efe9);
    background-image: url("../../public/images/bubble.svg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position-x: 20%;
    height:100vh;
    width:100vw;
    position: absolute;
}

.mobile-ui {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    height:90%;
    position: absolute;
    width:100vw;

}

.mobile-header {
    color: #2a2a2a;
    text-align: left;
    font-size: xx-large;
    padding:6% 4% 2% 4%;
    height:6%;
    font-family: "JetBrains Mono Extra Bold", monospace;
}

.mobile-block {
    backdrop-filter: blur(5px);
    background: rgba(234, 234, 234, 0.24);
}



.mobile-todos-summary {
    border-radius: 5px;
    grid-row: 2;
    grid-column: 2;
    margin:2% 4% 2% 0;
}

.board-nav {
    background: linear-gradient(50deg, #1e1e1e, #1a1a1a);
    position: relative;
}

.board-nav button {
    top:28%;
    background: linear-gradient(60deg, #363636, #525252);
    border:none;
    border-radius: 25px;
    padding: .5% 1%;
    margin: 1%;
    color:white;
    cursor: pointer;
    /*dis*/
}


.board-interface {
    margin: 10% auto;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(60px);
    border-radius: 20px;
    padding:1%;
}

.board-interface.vertical {
    min-height: 60%;
    margin: 1% auto;
    width:90%;

}
.board-interface.horizontal {
    min-height: 80%;
    width: 82%;
    margin: 10% 0 0 15%;
    right:0;
}



.horizontal {
    background-color: rgba(255, 255, 255, 0.3);
    display:grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
}

.vertical {
    background-color: rgba(255, 255, 255, 0.3);
    display:grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
}

.column {
    border-radius: 20px;
    margin:1%;
    padding:2%;
    border: 1px dashed black;
}

.horizontal .column {
    border-radius: 20px;
    margin:1%;
}

.vertical .column {

}

.horizontal .column-0 {
    grid-row: 1;
    grid-column: 1;
}

.horizontal .column-1 {
    grid-row: 2;
    grid-column: 1;
}

.horizontal .column-2 {
    grid-row: 3;
    grid-column: 1;
}

.horizontal .column-3 {
    grid-row: 4;
    grid-column: 1;
}

.vertical .column-0 {
    grid-row: 1;
    grid-column: 1;
}

.vertical .column-1 {
    grid-row: 1;
    grid-column: 2;
}

.vertical .column-2 {
    grid-row: 1;
    grid-column: 3;
}

.vertical .column-3 {
    grid-row: 1;
    grid-column: 4;
}

.column-header-container {
    display:grid;
    width: 90%;
    margin: 5% auto 1% auto;
    background-color: transparent;
}

.column-header-container .vertical {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    height:15%;
}

.column-header-container.horizontal {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
    position:absolute;
    height:80%;
    margin:11% 0 0 0;
    width:15%;
}

.column-header {
    margin:2%;
    border-radius: 10px;
    font-family: "JetBrains Mono", monospace;
    /*border: 0.003em solid black;*/
    /*display: inline-block;*/
}

.vertical .column-header {

}

.horizontal .column-header {
    border:none;
    margin:6%;
}

.vertical .header-0 {
    grid-row:1;
    grid-column:1;
    background: linear-gradient(60deg, #9ef3c3, #a1f6a5);
}

.vertical .header-1 {
    grid-row:1;
    grid-column:2;
    background: linear-gradient(60deg, #c9beff, #ffc1fe);
}

.vertical .header-2 {
    grid-row:1;
    grid-column:3;
    background: linear-gradient(60deg, #ffedbe, #fda58f);
}

.vertical .header-3 {
    grid-row:1;
    grid-column:4;
    background: linear-gradient(60deg, #befff0, #c8f2ff);
}

.horizontal .header-0 {
    grid-row:1;
    grid-column:1;
    background: linear-gradient(60deg, #9ef3c3, #a1f6a5);
}

.horizontal .header-1 {
    grid-row:2;
    grid-column:1;
    background: linear-gradient(60deg, #c9beff, #ffc1fe);
}

.horizontal .header-2 {
    grid-row:3;
    grid-column:1;
    background: linear-gradient(60deg, #ffedbe, #fda58f);
}

.horizontal .header-3 {
    grid-row:4;
    grid-column:1;
    background: linear-gradient(60deg, #befff0, #c8f2ff);
}