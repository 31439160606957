.week-day {
    grid-row:1/25;
    display:grid;
    position:relative;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(24, 1fr);
  }


  .month-day {
    grid-row:auto;
    background-color: rgb(14, 14, 14);
    height:12em;
    border-radius:10px;
    position:relative;
  }

.hour-block {
    font-family: "Inconsolata", monospace;
    color:grey;
    display:flex;
    font-size: small;
    align-items: center;
    justify-content: center;
    /* border: .01em solid rgb(56, 56, 56); */
    border-right: .3em solid #080808;
    border-bottom: .3em solid #080808;
}
