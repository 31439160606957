.spacrep-code-block, .code-inline {
  font-family: monospace;
  background: #212121;
  text-align: left;
  padding:2em 4em 2em 2em;
  border-radius: 10px;
  white-space: pre-wrap;
  line-height: 1.5em;
  display:inline-block;
  color: #ffa194;

  div {
    background:transparent;
  }
}


.code-inline {
  padding:.3em .8em;
  border-radius: 5px;
}

.spacrep-code-line {
  display: block;
}

.code-context-q {
  padding:2em;
}

.spac-rep-ul, .spac-rep-ol {
  text-align: left;

  li {
    display:inline-block;
  }
}