@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&family=Gloock&family=Inconsolata:wght@300&family=Inter:wght@300&family=Lobster&family=Lora:ital,wght@0,400;1,600&family=Merriweather:ital@1&family=Montserrat:wght@600&family=Nunito:wght@300&family=Playfair+Display:wght@500&family=Roboto+Mono:wght@300&family=Roboto:wght@100&family=Space+Grotesk:wght@500&display=swap");
:root {
  --card_width:250px;
  --card_border_radius:16px;
  --row_increment:10px;
  --card_small:26;
  --card_medium:33;
  --card_large:45;
}

.tracking-system-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  /*background:radial-gradient( #111111,#1e1e1e);*/
  bottom: 0;
}

.tracking-system-ui {
  margin: auto;
  top:5em;
}

.tracking-add-ref {
  width: 100vw;
  top: 5.5em;
  height: 3em;
  position: absolute;
}

.active-ref-container {
  margin: 11em auto;
  width: 100vw;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-auto-rows: 1px;
  overflow-y: scroll;
  height:90vh;
  align-items: flex-align;
  justify-content: center;
}

.inactive-select {
  border-radius: 20px;
  padding: 0.2em 1em 0.2em 2em;
  color: #d5d5d5;
  background: #151414;
  font-family: monospace;
  width:80%;
}

.tracking-add-ref select {
  height:30px;
}



.tracking-unit {
  padding: calc(4% + 2em) 1em;
  color: white;
  margin: 0.5em 0.4em;
  border-radius: 25px;
  box-sizing: border-box;
  display: flex;
  grid-template-rows: minmax(1fr, 750px);
  grid-template-columns: 100%;
  grid-row-end: span 242;
  position: relative;
  height: calc(230px);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  cursor:pointer;
  align-items: center;
  justify-content: center;
}

.tracking-unit:hover {
  /*height:235px;*/
  /*width:352px;*/
}

.tracking-unit:last-child {
  margin-bottom:4em;
}

.tracking-unit-btn-container {
  margin: 1em 0;
}



.alter-progress {
  cursor: pointer;
  border-radius: 50%;
  height: 1.5em;
  width: 1.5em;
  border: none;
  transform: translateY(-40%);
  background: #1e1e1e;
  color: white;
}
.alter-progress:hover {
  background: #7bc487;
}

.minus {
  margin-right: 0.5em;
  margin-bottom: 1.5em;
}

.plus {
  margin-left: 0.5em;
  margin-bottom: 1.5em;
}

.l-one {
  grid-row: 1;
  grid-column: 1;
}

.l-two {
  grid-row: 2;
  grid-column: 1;
}

.l-three {
  grid-row: 3;
  grid-column: 1;
}

.add-tracking-ref-bg {
  top: 0;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(20px);
  z-index: 98;
}

.add-tracking-ref-window {
  z-index: 99;
  min-height: 300px;
  width: 30vw;
  margin: 30vh 35vw;
  position: absolute;
  background: #161616;
  border-radius: 30px;
  color:white;
  padding: 2em 4em 0 4em;
  font-family: "JetBrains Mono", monospace;
  font-size: small;
}

.add-tracking-ref-window h2 {
  display: inline-block;
  margin: 18% 2% 4% 2%;
}

.add-ref-input {
  border: none;
  background: transparent;
  border-bottom: 1px dashed black;
  padding:.2em 2em;
  width: 100%;
}

.add-tracking-ref-window-sub {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  text-align: left;
  margin: 2%;
  margin-top: 3em;
}

.add-tracking-ref-window-sub .add-ref-label {
  margin-right: 2em;
  grid-column: 1;
  font-family: "Bitter", sans-serif;
  font-weight: 500;
}

.add-tracking-ref-window-sub .add-ref-value {
  margin-right: 2em;
  grid-column: 2;
}

.add-tracking-ref-window-sub button {
  grid-column: 1/3;
  margin: 2em auto 3em auto;
  border-radius: 35px;
  height: 2.5em;
  font-family: monospace;
  border: none;
  color: white;
  padding: .2em 2em;
  cursor: pointer;
  background: #111111;
}
.add-tracking-ref-window-sub button:hover {
  background: #1da6a0;
}


.add-tracking-ref-window-close {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  border-radius: 25px;
  height: 2em;
  border: none;
  background:  #111111;
  color: white;
  font-family: "Roboto Mono";
  cursor: pointer;
  padding:.3em 1em 1.3em 1em;
}
.add-tracking-ref-window-close:hover {
  background: #1da6a0;
}

@media screen and (max-width: 385px) {
  .active-ref-container {
    grid-template-columns: 90vw;
  }

  .inactive-select {
    max-width: 80vw;
  }
}


.tracking-unit.book {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #ab8eff);
}

.tracking-unit.article {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #38dfff);
}


.tracking-unit.video {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #65bd53);
}


.tracking-unit.research-paper {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #ee5fb3);
}

