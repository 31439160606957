.Interface {
  min-height: calc(90px*4 + 4%);
  max-width: calc(90px*8 + 16%);
  background-image: url("../images/highlands.jpg");
  background-size: 100%;
  background-attachment: fixed;
  background-position-y: 40%;
  margin: 10% auto;
  border-radius: 5px;
  padding: 1%;
  -webkit-margin-collapse: collapse;
}

.calendar-ui {
  background-color: #1a1a1a;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.container {
  height: calc(90px*4 + 4%);
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-bottom: 2%;
}

.calendar {
  position: relative;
}

.calender-nav {
  margin: 4% 4% 4% 4%;
  position: absolute;
  z-index: 99;
}

.calender-nav button {
  border-radius: 50%;
  outline: none;
  height: 90px;
  width: 90px;
  background: #1e1e1e;
  border: none;
  color: white;
}

.day-view {
  min-height: 20em;
  width: 100%;
  background: rgba(17, 17, 17, 0.52);
  border-radius: 25px;
  backdrop-filter: blur(15px);
  box-sizing: border-box;
  color: white;
  padding: 2% 5%;
  text-align: left;
  font-family: "JetBrains Mono", monospace;
}

@media (max-width: 400px) {
  .Interface {
    width: calc(7*47px);
    margin: 20% auto;
  }

  .calendar-nav {
    margin-top: 0;
  }
  .calendar-nav button {
    position: relative;
  }
}

