.auth-bar {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2em 3em;

}

.auth-bar button {
  background: none;
  border: none;
  color: #777777;
  cursor: pointer;
  font-family: "JetBrains Mono", monospace;
  font-size: small;
}

.auth-bar button:hover {
  color: #dedede;
}

.auth-bar a {
  font-weight: normal;
  font-family: "JetBrains Mono", monospace;
  font-size: small;
  display: block;
  width: 5em;
  color: #2f2f2f;
}


@media (max-width: 400px) {

  .auth-bar {
    bottom:7em;
    position:fixed;
    left:0;
    top:unset;
    right:unset;
    width:5em;
  }


}

