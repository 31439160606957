.workspace-container {
    position:absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    background: rgba(47, 47, 47, 0.18);
    backdrop-filter: blur(20px);
    z-index:99;
    display:flex;
    align-items: center;
}

.workspace-exit {
    position: absolute;
    top:0;
    left:0;
    margin:1.5em 2em;
}

.workspace-exit-btn:hover  {
    fill:white;
    cursor:pointer;
}

.detailed-view-ui {
    margin:auto;
    width:90vw;
    height:80vh;
    background: rgba(28, 28, 28, 0.63);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    display:grid;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 1em;
    padding:2em;
    border: .1em solid #101010;
}


.tracking-progress {
    grid-row: 7/13;
    grid-column: 2;
    background: #1a1a1a;
    border-radius: 20px;
    padding: 3em 1em 3em 1em;
}

.tracking-scroll {
    margin-bottom: 2em;
    overflow-y: scroll;
    width:100%;
    height:100%;
}

.chapters {
    grid-row:1/13;
    grid-column: 1;
    background: #171717;
    border-radius: 20px;
}


.book .tracking-progress {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #ab8eff);

}

.book .progression {
    background: linear-gradient(45deg, rgb(99, 34, 232), #987bff);
}

.article {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #38dfff);
}

.article .progression{
    background: linear-gradient(45deg, rgb(51, 131, 236), #3ebef5);
}

.video {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #65bd53);
}

.video .progression {
    background: linear-gradient(45deg, rgba(21, 255, 4, 0.55), #7ee567);
}

.research-paper {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.6), #ee5fb3);
}

.research-paper .progression{
    background: linear-gradient(45deg, rgba(255, 7, 185, 0.91), #ca47e7);
}

.tracking-unit-ref-metadata {
    grid-column: 1;
    grid-row: 1;
    padding-bottom: 2em;
}

.tracking-ref-title {
    font-family: "JetBrains Mono", monospace;
    margin: 2% 1% 4% 1%;
    font-size: medium;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.34);
}

.tracking-ref-author {
    font-family: monospace;
    color: #fcfcfc;
    font-size: small;
}

.tracking-unit-menu {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    z-index: 99;
    position: absolute;
    background: rgba(30, 30, 30, 0.4);
    backdrop-filter: blur(4px);
}

.tracking-unit-menu-buttons {
    margin: 40% auto;
}

.tracking-unit-menu-button {
    font-family: "JetBrains Mono", monospace;
    color: #ffffff;
    background: rgba(26, 26, 26, 0.75);
    border-radius: 5px;
    border: 0.01em solid;
    padding: 1% 2%;
    cursor: pointer;
}


.bar {
    height: 20px;
    border-radius: 15px;
}

.bar-read-watch {
    /*background: linear-gradient(60deg, #60efe9, #9ef3ad);*/
}

.bar-summarize {
    /*background: linear-gradient(60deg, #9ef3ad, #a59840);*/
}

.bar-process {
    /*background: linear-gradient(60deg, #f3e29e, #6322e8);*/
}


.tracking-progress-bar:hover .progression {
    /*background: linear-gradient(60deg, #9ef3af, #e822d8);*/
}
.tracking-progress-bar:hover .tracking-label {
    /*color: #41e31c;*/
}

.progression {
    box-shadow: inset 6px 0 10px rgba(23, 23, 23, 0.13);
}

.progression {
    height: 1.5em;
    border-radius: 30px 0 0 30px;
    z-index: 2;
}