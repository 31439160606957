.config-link {
  position: fixed;
  width: 8%;
  top: 10%;
  border-radius: 15px;
  text-align: left;
  padding-left: 0.5%;
  cursor: pointer;
  right: 0;
}
.no-cards-left {
  position: absolute;
  top: 45%;
  width: 100vw;
  font-family: "JetBrains Mono Extra Bold", monospace;
  color: #c9beff;
}

.config-link:hover svg {
  fill: white;
}

@media (max-width: 400px) {
  .config-link {
    text-align: right;
    height: fit-content;
    top: 0.8em;
    left: 0.8em;
    z-index: 99;
  }
}

