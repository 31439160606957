.card-rep-container {
  width:100%;
  height:100%;
  display:grid;
  grid-row: 1;
  grid-column: 1;
  grid-template-columns: 1fr;
  grid-template-rows: 7% minmax(20%, auto) minmax(20%, auto);
}

.card-rep-front {
  display:flex;
  align-items: center;
  justify-content: center;
  grid-row:2;
  grid-column: 1;

  span {
    display:inline-block;
  }
}

.card-rep-back {
  grid-row: 3;
  grid-column: 1;
  display:flex;
  align-items: self-start;
  justify-content: center;
  overflow-y:scroll;
  overflow-x:hidden;
  white-space: break-spaces;
  word-break: normal;

  span {
    display:inline-block;
  }
}

.card-rep-reference {
  grid-row:1;
  grid-column: 1;
  display:flex;
  align-items: center;
  color:#d5f8f8;
}

li {
  display: block;
  float:none;
  display:list-item;
  margin:0;
  padding:0;
}

ol, ul {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.spacrep-img {
  overflow:hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
