.week-event {
    position:absolute;
    width:96%;
    color:rgba(255, 255, 255, 0.658);
    font-family: "Aptos";
    display: flex;
    align-items: center;
    justify-content: center;
}

.month-event {
    position:absolute;
    width:1em;
    height:1em;
    margin:.5em .25em;
    top:0;
    left:.2em;
    right:.2em;
    background-color: rgb(255, 255, 0);
    color:rgba(255, 255, 255, 0.658);
    font-family: "Aptos";
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.study {
    background: linear-gradient(40deg, blue, purple);
}

.read {
    background: linear-gradient(40deg, rgb(101, 207, 14), rgb(34, 156, 187));
}

.exercise {
    background: linear-gradient(40deg, rgb(255, 0, 234), rgb(77, 120, 240));
}

.meeting {
    background: linear-gradient(40deg, rgb(255, 250, 91), rgb(225, 163, 31));
}

.drag-time-display {
    background-color: rgb(56, 56, 56);
    backdrop-filter: blur(20px);
    position:absolute;
    z-index:8;
    width:3em;
    height:1.5em;
    border-radius: 5px;
    left:-3.2em;
    color:white;
    display:flex;
    text-align: center;
    justify-content: center;
    font-family: monospace;
    align-items: center;
    padding: 0 .1em;
}

