@import "../day/day.css";

.CalendarMenu {
  background: mediumpurple;
}

.CalendarMenu button {
  cursor:pointer;
  color:white;
}


.CalendarMenu button:hover {
  animation: button-bounce 1 200ms ease-out;
}

.add-task.btn {
  background: linear-gradient(45deg, #a6a6e1, #89ff89);
  width:35px;
  height:35px;
  margin:40%;
  color:#1a1a1a;
}

.task-choice-menu {
  position: absolute;
  right:-90px;
  border-radius: 50px;
  padding:3%;
}

.task-choice-menu button {
  margin:1%;
}

.task-choice-menu button:hover {
  animation: button-bounce 1 200ms ease-out;
}