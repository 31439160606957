.SearchInterface {
  background: #1a1a1a;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.searchbar {
  margin: 2% auto;
}
.searchbar input {
  min-width: 30%;
  border-radius: 45px;
  height: 1.5em;
  padding: 0.3em 1em;
  font-family: "JetBrains Mono", monospace;
  border: none;
  outline: none;
}

.filter-ui-container {
  position: relative;
  top: 40%;
}

.filterbar {
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.filterbar .filter-container {
  display: table;
  border-spacing: 5px;
  color: white;
  cursor: pointer;
  font-family: "JetBrains Mono", monospace;
}
.filterbar .filter-cat {
  display: table-row;
  min-width: 200px;
}
.filterbar .filter-blob {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: table-cell;
}
.filterbar .filter-blob.domain {
  background-color: #8426bb;
}
.filterbar .filter-blob.field {
  background-color: #1da6a0;
}
.filterbar .filter-blob.object {
  background-color: #84a61d;
}
.filterbar .span {
  display: table-cell;
}
.filterbar .filter {
  min-width: 200px;
  margin: 0 3%;
}

.options-container {
  position: absolute;
  text-align: left;
  top: 0;
  color: #4f4f4f;
  transform: translateY(-106%);
}
.options-container .option:hover {
  color: white;
}

.smart-search {
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.71);
  color: purple;
  width: 30%;
  margin: 0 auto;
}

