.protocard {
  width: 80vw;
  margin: 2% auto;
  color: rgba(255, 255, 255, 0.84);
}
.front-card {
  margin-bottom: 1% !important;
  font-family: "Arial Nova", sans-serif;
  letter-spacing: 0.05em;
  padding: 1% 2% 2% 2%;
  min-height: 6em;
}

.back-card {
  font-family: "Arial Nova", sans-serif;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 4%;
  -ms-overflow-style: none;
  overflow-y: scroll;

}
.back-card ul {
  width: 100%;
}


.protocard ul, .protocard ol {
  /*padding-left: 15%;*/
  text-align: left;
  /*margin-left: 0;*/
  top:0;
  word-wrap:break-word;
  /*text-indent: -20px;*/
  list-style: none; /* Remove default list styling */
  padding-left: 2em;
}
ol li {
  margin-bottom:10px;
  display: flex; /* Use flexbox for alignment */
  align-items: flex-start; /* Aligns marker with the top of the text */
}

ol li p {
  /*text-indent:-1em;*/
  margin:0;
  /*padding-left:1em;*/
}

ol li {
  display: flex; /* Use flexbox for alignment */
  align-items: flex-start; /* Aligns marker with the top of the text */
  margin-bottom: 10px;
}

ol li::before {
  margin-top:.35em;
  content: counter(item) ". "; /* Custom counter for numbers */
  counter-increment: item;
  margin-right: 10px; /* Space between marker and text */
  font-weight: bold;
}

ul li {
  display: flex; /* Use flexbox for alignment */
  align-items: flex-start; /* Align bullet with the top of the text */
  margin-bottom: 10px;
}

ul li::before {
  content: "•"; /* Custom bullet */
  margin-right: 10px; /* Space between bullet and text */
  font-size: 1.2em; /* Adjust bullet size */
  line-height: 1.2em; /* Match line height with the text */
}

ul li p {
  margin: 0; /* Remove extra margin on paragraphs */
}

ol {
  counter-reset: item; /* Initialize the counter */
}

ol li p {
  margin: 0; /* Remove extra margin on paragraphs */
}


.protocard li > * {
  /*vertical-align: text-top;*/
}



ol {
  counter-reset: item; /* Initialize the counter */
}

.back-card::-webkit-scrollbar {
  display:none;
}

.back-card * {
  text-align: center;
}

.definition.back-card, .set.back-card, .list.back-card, .translation.back-card {
  text-align: center;
  max-width: fit-content;
}
.definition ol, .set ol, .list ol, .translation ol {
  text-align: center;
  width: fit-content;
}

.code-div {
  background: #1c1c1c;
  margin: 0 auto;
  font-family: monospace;
  text-align: left;
  padding: 12px 15px;
  border-radius: 15px;
  color: #dcdcdc;
  position: relative;
}

.vis-img {
  max-width: 400px;
}

.lang {
  font-family: "JetBrains Mono", monospace;
  color: #525252;
  font-size: small;
}

@media (max-width: 400px) {
  .back-card, .code-div {
    max-width: 100%;
    -ms-overflow-style: none;
  }

  .back-card::-webkit-scrollbar {
    display:none;
    max-height:35vh;
    overflow-y:scroll;
  }

  .front-card {
    min-height: unset;
    padding-bottom:2em;
    padding-top:1em;

  }

  .vis-img {
    width: 100vw;
  }

  .protocard {
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 67vh;
  }


  .protocard ul, .protocard ol {
    /*padding-left: 15%;*/
    text-align: left;
    /*margin-left: 0;*/
    top:0;
    word-wrap:break-word;
    /*text-indent: -20px;*/
    list-style: none; /* Remove default list styling */
    padding-left: 2em;
  }
   ol li {
     margin-bottom:10px;
     display: flex; /* Use flexbox for alignment */
     align-items: flex-start; /* Aligns marker with the top of the text */
   }

   ol li p {
     /*text-indent:-1em;*/
     margin:0;
     /*padding-left:1em;*/
   }

  ol li {
    display: flex; /* Use flexbox for alignment */
    align-items: flex-start; /* Aligns marker with the top of the text */
    margin-bottom: 10px;
  }

  ol li::before {
    margin-top:.35em;
    content: counter(item) ". "; /* Custom counter for numbers */
    counter-increment: item;
    margin-right: 10px; /* Space between marker and text */
    font-weight: bold;
  }

  ul li {
    display: flex; /* Use flexbox for alignment */
    align-items: flex-start; /* Align bullet with the top of the text */
    margin-bottom: 10px;
  }

  ul li::before {
    content: "•"; /* Custom bullet */
    margin-right: 10px; /* Space between bullet and text */
    font-size: 1.2em; /* Adjust bullet size */
    line-height: 1.2em; /* Match line height with the text */
  }

  ul li p {
    margin: 0; /* Remove extra margin on paragraphs */
  }

  ol {
    counter-reset: item; /* Initialize the counter */
  }

  ol li p {
    margin: 0; /* Remove extra margin on paragraphs */
  }


  .protocard li > * {
    /*vertical-align: text-top;*/
  }



  ol {
    counter-reset: item; /* Initialize the counter */
  }



  .front-card span, .back-card span {
    font-size: small;
  }

  .set .back-card ul span {
    text-align: left;
    font-size: small;
  }

  .list .back-card ol span, .definition .back-card ol span, .translation .back-card ul span, .synonym .back-card ul span {
    text-align: left;
    font-size: small;
  }

  .synonym .back-card ul {
    margin-left: 1em;
  }

  .definition .back-card span, .code .back-card span, .cloze .back-card span {
    font-size: small;
  }

  .list .back-card ol {
    margin-left: -1.2em;
  }
}
.hoi {
  display: inline-block;
  width: fit-content;
  margin: auto;
}


.full-text {
//display: inline-block;
  flex-wrap: wrap;
  text-overflow: clip;
  white-space: pre-wrap;
  overflow-wrap:break-word;
}
.full-text span {
  white-space: pre-wrap;
  overflow-wrap:break-word;
  display: inline-block;
  margin-top:.2em;
}